<template>
  <div>
    <v-row class="py-1">
      <v-col>
        <v-btn
          color="dark"
          text
          small
          @click="redirect()"
        >
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-container fluid>
      <v-card :loading="isLoading">
        <v-row>
          <v-col
            class="form-section"
            cols="4"
          >
            <v-form
              ref="form"
              lazy-validation
            >
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col
                      v-if="authUser.isAdmin"
                      cols="12"
                    >
                      <v-select
                        v-model="options.tenant_id"
                        dense
                        :items="tenantList"
                        item-value="id"
                        item-text="name"
                        :disabled="type == 'edit'"
                        :label="$t('select_tenant_*')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="options.name"
                        dense
                        :label="$t('route_name_*')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="options.start_location_id"
                        dense
                        :loading="isLocLoading"
                        :items="locOptions"
                        item-text="location"
                        item-value="id"
                        :label="$t('start_position_*')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                  </v-row>
                  <!-- add stops and add Location -->
                  <add-stops
                    v-model="options.stop_location_ids"
                    :type="type"
                    :items="options"
                  />
                  <!-- add stops and add Location -->
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="options.stop_duration_in_minutes"
                        dense
                        type="number"
                        :label="$t('stop_duration_in_minutes_*')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="options.is_active"
                        dense
                        :items="statusOptions"
                        item-text="name"
                        item-value="value"
                        :label="$t('status_*')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        v-model="options.return_to_depot"
                        :label="$t('return_to_start_position')"
                      />
                      <!-- :rules="[rules.required]" -->
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        v-model="options.optimize_sequence"
                        :label="$t('optimize_sequence')"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />

                  <v-btn
                    color="primary"
                    text
                    :loading="isLoading"
                    @click="saveRoute()"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
          <!-- job Sequence -->
          <v-col cols="2">
            <v-card class="job-seq">
              <v-card-title>
                <span>{{ $t("job_sequence") }}</span>
              </v-card-title>
              <v-card-text>
                <div
                  v-for="(r, i) in job_seq"
                  :key="i"
                >
                  <span>{{ i + 1 }} - </span>
                  <span>{{ r }}</span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- job Sequence -->
          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <div class="info-tag">
                  <v-card
                    v-for="(row, key) in tags"
                    :key="key"
                  >
                    <v-card-text>
                      <label>{{ row.key?.replace(/_/g, " ") }}</label>
                      <span>{{ row.value }}</span>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12">
                <action-route-map />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  components: {
    addStops: () => import("./AddStops.vue"),
    // addLocation: () => import("./AddLocation.vue"),
    actionRouteMap: () => import("./ActionRouteMap.vue"),
  },
  mixins: [validationRules],
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    type: {
      type: String,
      default() {
        return "";
      },
    },
  },

  data() {
    return {
      options: {},
      //
      //
      statusOptions: [
        { value: "inactive", name: "In-Active" },
        { value: "active", name: "Active" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "routeListing/getIsLoading",
      tenantList: "tenantsManagement/getTenantOptions",
      //
      isLocLoading: "truckistanRoute/getLocationsLoading",
      locOptions: "truckistanRoute/getLocationOptions",
      //
      routePlan: "routeListing/getRoutePlan",
    }),
    job_seq() {
      let arr = [];
      if (this.routePlan.id) {
        arr = this.routePlan.api_response[0].activities.map(
          (r) => r.address.location_id
        );
      }
      return arr.length > 0 ? arr : [];
    },
    tags() {
      const row = this.routePlan;
      const arr = [];
      arr.push({ key: "distance", value: row?.distance });
      arr.push({ key: "cost", value: row?.cost });
      arr.push({ key: "transport_time", value: row?.transport_time });
      arr.push({ key: "completion_time", value: row?.completion_time });
      return arr;
    },
  },
  watch: {
    "options.tenant_id": {
      handler: function (v) {
        const params = { tenant_id: v };
        this.$store.dispatch("truckistanRoute/getLocations", params);
      },
    },
  },
  async mounted() {
    // when items have value  case for edit
    if (this.type == "edit") {
      this.fetchData();
    }
    if (this.authUser.isAdmin) {
      // const value
    } else {
      this.options.tenant_id = this.authUser.tenant_id;
      this.getLocations(this.options.tenant_id);
    }
  },
  methods: {
    saveRoute() {
      if (this.$refs.form.validate()) {
        this.$emit("onsave", this.options);
      }
    },
    //

    redirect() {
      this.$router.push({
        name: "route_listing",
      });
    },
    getLocations(v) {
      const params = { tenant_id: v };
      this.$store.dispatch("truckistanRoute/getLocations", params);
    },
    // for get One Edit Case
    async fetchData() {
      const id = this.$route.params.id;
      await this.$store.dispatch("routeListing/getOne", { id }).then((res) => {
        this.options.tenant_id = res.tenant_id;
        this.getLocations(res.tenant_id);
        this.options.name = res.route_name;
        this.options.start_location_id = res.start_location_id;
        this.options.stop_location_ids = res.stop_ids;
        this.options.stop_duration_in_minutes = res.stop_duration_in_minutes;
        this.options.is_active = res.is_active == "1" ? "active" : "inactive";
        this.options.return_to_depot = res.return_to_depot;
        this.options.optimize_sequence = res.optimize_sequence;
      });
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .v-input--is-disabled
  .v-select__selection
    color: gray !important

:deep .v-select__selection--disabled
  color: #000 !important

.form-section
  height: 460px
  overflow-y: auto

.job-seq
  .v-card__text
    font-size: 12px

.info-tag
  display: flex
  flex-wrap: wrap
  gap: 0.5rem
  .v-card
    flex-basis: 300px
    margin: 0
    .v-card__text
      display: flex
      justify-content: space-between
      label
        text-transform: capitalize
        font-size: 13px
        span
          font-weight: 600
</style>
